import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import Modal from "../../common/modal"
import Button from "../../form/button"
import Input from "../../form/input"
import Layout from "../../../layouts/app"
import Table from "./table"
import StoreContext from "../../../context/storeContext"

const initialCustomerData = {
  name: "",
  phone: "",
  altPhone: "",
  email: "",
  district: "",
  address: "",
}

export default function Customers() {
  const { loading, customers, addDocument, updateDocument } = useContext(StoreContext)

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [customerData, setCustomerData] = useState(initialCustomerData)

  const handleChange = e => {
    const { name, value } = e.target
    setCustomerData({ ...customerData, [name]: value })
  }

  const handleAddCustomer = async e => {
    e.preventDefault()

    try {
      addDocument("customers", customerData)
      setAddModalOpen(false)
      setCustomerData(initialCustomerData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddQuotation = async customer => {
    try {
      const docRefId = await addDocument("quotations", { customerId: customer.id, status: "Open" })
      navigate(`/app/quotations/${docRefId}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout title="Customers">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Customers</h1>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto" />
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button label="Add Customer" onClick={() => setAddModalOpen(true)} />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : customers.length > 0 ? (
        <>
          <Table customers={customers} update={updateDocument} addQuotation={handleAddQuotation} />
        </>
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Customers</div>
      )}

      <Modal title="Add Customer" isOpen={addModalOpen} closeModal={() => setAddModalOpen(false)}>
        <form onSubmit={handleAddCustomer} className="grid gap-y-2">
          <Input type="text" label="Name" name="name" required value={customerData.name} onChange={handleChange} />
          <Input type="text" label="Phone" name="phone" required value={customerData.phone} onChange={handleChange} />
          <Input type="text" label="Alt Phone" name="altPhone" value={customerData.altPhone} onChange={handleChange} />
          <Input type="email" label="Email" name="email" value={customerData.email} onChange={handleChange} />
          <Input type="text" label="District" name="district" required value={customerData.district} onChange={handleChange} />
          <Input type="text" label="Address" name="address" value={customerData.address} onChange={handleChange} />
          <Button type="submit" label="Add Customer" className="mt-4" />
        </form>
      </Modal>
    </Layout>
  )
}
