import React from "react"

export default function Button({ type = "button", disabled, loading, label, onClick, className, labelClassName, preIcon }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto ${(disabled || loading) ? "cursor-not-allowed bg-blue-200" : "bg-blue-600 hover:bg-blue-700"} ${className ?? ""}`}
    >
      {preIcon}
      <span className={`text-sm font-medium text-white ${labelClassName ?? ""}`}>{loading ? "Processing..." : label}</span>
    </button>
  )
}
