import React from "react"
import { LogoutIcon, MenuAlt2Icon } from "@heroicons/react/outline"
import { logout } from "../../utils"

export default function Header({ title, setSidebarOpen }) {
  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button type="button" className="px-4 border-r border-gray-200 text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex items-center justify-between">
        <div className="">
          <h1 className="text-lg font-semibold text-gray-600">{title}</h1>
        </div>
        <div className="ml-4">
          <button onClick={logout} className="ml-4 flex items-center space-x-1 text-gray-900 text-base font-medium cursor-pointer hover:text-red-500">
            <LogoutIcon className="w-6 h-6" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  )
}
