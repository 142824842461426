import React, { useContext } from "react"
import Layout from "../../../layouts/app"
import Table from "./table"
import StoreContext from "../../../context/storeContext"

export default function Users() {
  const { loading, users, updateDocument } = useContext(StoreContext)

  return (
    <Layout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : users.length > 0 ? (
        <>
          <Table users={users} update={updateDocument} />
        </>
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No user</div>
      )}
    </Layout>
  )
}
