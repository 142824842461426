import React, { useState, useEffect, useMemo } from "react"
import { useTable, useGlobalFilter } from "react-table"
import Input from "../form/input"

export default function List({ main, customers, cols }) {
  const columns = useMemo(() => cols, [cols])
  const [data, setData] = useState([])

  useEffect(() => {
    setData(
      main.map((m, i) => {
        const { name, phone, altPhone } = customers.find(f => f.id === m.customerId) || ""
        return { ...m, name, phone, altPhone }
      })
    )
  }, [main, customers])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance

  return (
    <div className="mt-4 shadow rounded-md bg-white">
      <div className="p-2">
        <Input type="text" placeholder="Filter your customers ..." value={globalFilter || ""} onChange={e => setGlobalFilter(e.target.value)} className="py-1.5" />
      </div>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className={rowIndex % 2 === 0 ? undefined : "bg-gray-50"}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
