import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import React, { Fragment } from "react"

export default function Modal({ isOpen, closeModal, className, title, titleClassName, children, dialogClassName }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={`relative z-10 ${dialogClassName ?? ""}`} onClose={closeModal}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <div className={`w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${className ?? ""}`}>
                <div className="flex items-center justify-between mb-4">
                  <h1 className={`text-gray-700 text-lg font-medium ${titleClassName ?? ""}`}>{title}</h1>
                  <div role="button" tabIndex={0} onClick={closeModal} onKeyDown={closeModal} className="w-7 h-7 rounded-md hover:bg-red-100 flex items-center justify-center cursor-pointer">
                    <XIcon className="w-6 h-6 text-red-500" />
                  </div>
                </div>
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
