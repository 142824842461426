import React from "react"

export default function Input({ label, type, name, value, onChange, error, placeholder, prefix, description, className, labelClassName, containerClassName, errorClassName, descriptionClassName, ...rest }) {
  return (
    <div className={`${containerClassName ?? ""}`}>
      {label && (
        <label htmlFor={name} className={`block text-sm font-medium text-gray-600 mb-1 ${labelClassName ?? ""}`}>
          {label}
        </label>
      )}
      <div className="flex rounded-md shadow-sm">
        {prefix && <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-900 sm:text-sm">{prefix}</span>}
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          className={`flex-1 min-w-0 block w-full px-3 py-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 placeholder-gray-300 ${prefix ? "rounded-r-md" : "rounded-md"} ${className ?? ""}`}
          placeholder={placeholder}
          {...rest}
        />
      </div>
      {description && <p className={`mt-1 text-sm text-gray-900 ${descriptionClassName ?? ""}`}>{description}</p>}
      {error && <p className={`mt-1 text-xs text-red-500 ${errorClassName ?? ""}`}>{error}</p>}
    </div>
  )
}
