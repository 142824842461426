import React from "react"
import Layout from "../../layouts/app"

export default function Dashboard() {
  return (
    <Layout title="Dashboard">
      <h1>Welcome back!</h1>
      <br />
      <p>More on this page coming soon...</p>
    </Layout>
  )
}
