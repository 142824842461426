import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline"
import React, { useEffect, useMemo, useState } from "react"
import { useTable } from "react-table"
import { frameOptions, glassOptions, ORDERS_PANNELS, productOptions, PRODUCTS_COLUMNS } from "../../../data"
import Select from "../../form/select"
import { getLocaleString, getPanelWidth } from "../../../utils"
import Modal from "../../common/modal"
import Button from "../../form/button"

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
  editableRowIndex, // index of the row we requested for editing
}) => {
  const [value, setValue] = useState(initialValue)

  const onChangeNumber = e => {
    setValue(Number(e.target.value))
  }

  // We'll only update the external data when the input is blurred
  const onBlurNumber = () => {
    updateData(index, id, Number(value))
  }

  const handleSelectChange = selectedProduct => {
    updateData(index, id, selectedProduct.name)
  }

  // If the initialValue is changed externall, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return index === editableRowIndex && !["time", "area", "price", "subTotal"].includes(id) ? (
    id === "product" ? (
      <Select options={productOptions} selectedOption={productOptions.find(option => option.name === value)} setSelectedOption={option => handleSelectChange(option)} inputClassName="py-1" />
    ) : id === "glass" ? (
      <Select options={glassOptions} selectedOption={glassOptions.find(option => option.name === value)} setSelectedOption={option => handleSelectChange(option)} inputClassName="py-1" />
    ) : id === "frame" ? (
      <Select options={frameOptions} selectedOption={frameOptions.find(option => option.name === value)} setSelectedOption={option => handleSelectChange(option)} inputClassName="py-1" />
    ) : (
      <input value={value} type="number" onChange={onChangeNumber} onBlur={onBlurNumber} className="focus:ring-0 focus:border-blue-500 focus:outline-none block w-full sm:text-sm border border-gray-300 rounded py-1" />
    )
  ) : (
    <p>{value}</p>
  )
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

const SummaryItem = ({ name, value }) => (
  <div className="flex space-x-3 items-center justify-between mt-1">
    <p className="text-sm font-medium text-gray-900">{name}</p>
    <p className="text-sm text-gray-900">
      <span className="text-gray-400">SCR</span> {value}
    </p>
  </div>
)

export default function ProductTable({ products, update, orderId, payments }) {
  const columns = useMemo(() => {
    const shiftedColumns = [...PRODUCTS_COLUMNS]
    shiftedColumns.shift()
    return shiftedColumns
  }, [])

  const [data, setData] = useState([])
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [editableRowIndex, setEditableRowIndex] = useState(null)
  const [removableRowIndex, setRemovableRowIndex] = useState(null)
  const [cutProduct, setCutProduct] = useState(null)
  const [selectedPanelNo, setSelectedPanelNo] = useState(ORDERS_PANNELS[0])

  useEffect(() => {
    setData(products)
  }, [products])

  const updateData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)

    const updatedData = data.map((row, index) => {
      if (index === rowIndex) {
        const updatedRow = {
          ...data[rowIndex],
          [columnId]: value,
        }
        const area = updatedRow.width * updatedRow.height * updatedRow.quantity
        const price = productOptions.find(option => option.name === updatedRow.product)?.price + glassOptions.find(option => option.name === updatedRow.glass)?.extra
        const subTotal = area * price

        return {
          ...updatedRow,
          area,
          price,
          subTotal,
        }
      }
      return row
    })
    update("orders", orderId, { products: updatedData })
    setData(updatedData)
  }

  const removeProduct = () => {
    const filteredData = data.filter((row, index) => index !== removableRowIndex)
    update("orders", orderId, { products: filteredData })
    setData(filteredData)
    setRemovableRowIndex(null)
  }

  // const calculateCut = () => {
  //   const { area, height, price, quantity, subTotal, width } = cutProduct
  //   const CasingHHeight = height
  // }

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateData,
      removeProduct,
      editableRowIndex,
      setEditableRowIndex,
      removableRowIndex,
      setRemovableRowIndex,
    },
    hooks => {
      hooks.allColumns.push(columns => [
        {
          accessor: "product",
          id: "product",
          Header: "Product",
          Cell: ({ row }) => (
            <button onClick={() => setCutProduct(row.original)} className="whitespace-nowrap px-2 py-2 text-sm text-blue-500">
              {row.original.product}
            </button>
          ),
        },
        // other hooks such as selection hook
        ...columns,
        // edit hook
        {
          accessor: "edit",
          id: "edit",
          Header: "Action",
          Cell: ({ row, setEditableRowIndex, editableRowIndex }) => (
            <div className="flex items-center space-x-3">
              <button
                className={`font-medium text-sm flex items-center space-x-0.5 ${editableRowIndex !== row.index ? "text-blue-500 hover:text-blue-600" : "text-green-500 hover:text-green-600"}`}
                onClick={async () => {
                  const currentIndex = row.index
                  if (editableRowIndex !== currentIndex) {
                    setEditableRowIndex(currentIndex)
                  } else {
                    setEditableRowIndex(null)
                  }
                }}
              >
                {editableRowIndex !== row.index ? (
                  <>
                    <PencilIcon className="w-4 h-4" /> <span>Edit</span>
                  </>
                ) : (
                  <>
                    <CheckIcon className="w-5 h-5" /> <span>Save</span>
                  </>
                )}
              </button>
              <button onClick={() => setRemovableRowIndex(row.index)} className="font-medium text-sm flex items-center space-x-0.5 text-red-500 hover:text-red-600">
                <TrashIcon className="w-4 h-4" /> <span>Remove</span>
              </button>
            </div>
          ),
        },
      ])
    }
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const priceExVat = products.reduce((a, b) => {
    return a + b.subTotal
  }, 0)
  const vat = priceExVat * 0.15
  const priceTotal = priceExVat + vat
  const totalPaid = payments.reduce((a, b) => {
    return b.status === "Processed" ? a + b.amount : a
  }, 0)
  const balance = priceTotal - totalPaid

  return (
    <div className="mt-4 shadow rounded-md bg-white">
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="w-full divide-y divide-gray-300 border-b border-gray-100">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className={rowIndex % 2 === 0 ? undefined : "bg-gray-50"}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex sm:justify-between p-4">
        <div className="hidden sm:block">{/* Pagination */}</div>
        <div className="w-full sm:w-auto">
          <SummaryItem name="Total ex VAT" value={getLocaleString(priceExVat)} />
          <SummaryItem name="VAT 15%" value={getLocaleString(vat)} />
          <SummaryItem name="Total inc VAT" value={getLocaleString(priceTotal)} />
          <SummaryItem name="Total Paid" value={getLocaleString(totalPaid)} />
          <SummaryItem name="Balance" value={getLocaleString(balance)} />
        </div>
      </div>
      <Modal title="Confirmation" isOpen={!!removableRowIndex || removableRowIndex === 0} closeModal={() => setRemovableRowIndex(null)} className="max-w-[22rem]">
        <p className="text-red-500">Are you sure you wanna remove ?</p>
        <div className="w-full flex items-center space-x-3 mt-4">
          <Button onClick={() => setRemovableRowIndex(null)} label="Cancel" className="w-full sm:w-full bg-gray-500 hover:bg-gray-600" />
          <Button onClick={removeProduct} label="Remove" className="w-full sm:w-full bg-red-500 hover:bg-red-600" />
        </div>
      </Modal>
      <Modal title="Cut" isOpen={!!cutProduct} closeModal={() => setCutProduct(null)} className="max-w-[22rem]">
        <Select label="Pannels" options={ORDERS_PANNELS} selectedOption={selectedPanelNo} setSelectedOption={setSelectedPanelNo} />
        <div className="mt-6 grid grid-cols-2 gap-4">
          <div>
            <p className="text-base text-gray-600 font-medium mb-1">Casing (H)</p>
            <p className="text-sm text-gray-600">
              Height : <span className="text-gray-900 ml-2">{cutProduct?.height}</span>
            </p>
            <p className="text-sm text-gray-600">
              Quantity : <span className="text-gray-900 ml-2">{selectedPanelNo.name}</span>
            </p>
          </div>
          <div>
            <p className="text-base text-gray-600 font-medium mb-1">Panel (H)</p>
            <p className="text-sm text-gray-600">
              Height : <span className="text-gray-900 ml-2">{cutProduct?.height - 40}</span>
            </p>
            <p className="text-sm text-gray-600">
              Quantity : <span className="text-gray-900 ml-2">{selectedPanelNo.name}</span>
            </p>
          </div>
          <div>
            <p className="text-base text-gray-600 font-medium mb-1">Casing (W)</p>
            <p className="text-sm text-gray-600">
              Width : <span className="text-gray-900 ml-2">{cutProduct?.width - 12}</span>
            </p>
            <p className="text-sm text-gray-600">
              Quantity : <span className="text-gray-900 ml-2">1</span>
            </p>
          </div>
          <div>
            <p className="text-base text-gray-600 font-medium mb-1">Panel (W)</p>
            <p className="text-sm text-gray-600">
              Width : <span className="text-gray-900 ml-2">{getPanelWidth(selectedPanelNo.name, cutProduct?.width)}</span>
            </p>
            <p className="text-sm text-gray-600">
              Quantity : <span className="text-gray-900 ml-2">{selectedPanelNo.name}</span>
            </p>
          </div>
          <div className="col-span-2">
            <p className="text-base text-gray-600 font-medium mb-1">Glass</p>
            <p className="text-sm text-gray-600">
              Width : <span className="text-gray-900 ml-2">{getPanelWidth(selectedPanelNo.name, cutProduct?.width) - 65}</span>
            </p>
            <p className="text-sm text-gray-600">
              Height : <span className="text-gray-900 ml-2">{cutProduct?.height - 40 - 85}</span>
            </p>
            <p className="text-sm text-gray-600">
              Quantity : <span className="text-gray-900 ml-2">{selectedPanelNo.name}</span>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  )
}
