import React, { useState, useContext, useRef } from "react"
import ReactToPdf from "react-to-pdf"
import { arrayUnion } from "firebase/firestore"

import Modal from "../../common/modal"
import Button from "../../form/button"
import Input from "../../form/input"
import Layout from "../../../layouts/app"
import Products from "./products"
import { frameOptions, glassOptions, productOptions } from "../../../data"
import Select from "../../form/select"
import CustomerCard from "./customer"
import StoreContext from "../../../context/storeContext"

import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const initialProductData = {
  width: "",
  height: "",
  quantity: "",
}

// const pdfOptions = {
//   orientation: "landscape",
// }

const nodeList = [
  "- One measurement and fixing is included, additional site vists incur a SCR 500 fee",
  "- 50% deposit must be paid on order confirmation, 50% balance must be paid before installation",
  "- Please ensure any construction work, wooden frames and louvers are removed before installation",
  "- Subsequent changes requested after payment can incur extra charges",
]

export default function QuotationDetails({ quotationId }) {
  const { loading, quotations, customers, orders, addDocument, updateDocument } = useContext(StoreContext)
  const quotation = quotations.find(f => f.id === quotationId)
  const order = orders.find(f => f.quotationId === quotationId)

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [addBulkModalOpen, setAddBulkModalOpen] = useState(false)
  const [productData, setProductData] = useState(initialProductData)

  const [selectedProduct, setSelectedProduct] = useState(productOptions[0])
  const [selectedGlass, setSelectedGlass] = useState(glassOptions[0])
  const [selectedFrame, setSelectedFrame] = useState(frameOptions[0])
  const [selectedBulkQuantity, setSelectedBulkQuantity] = useState(5)
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false)
  const [addBulkLoading, setAddBulkLoading] = useState(false)

  const pdfRef = useRef()

  const handleChangeNumber = e => {
    const { name, value } = e.target
    setProductData({ ...productData, [name]: Number(value) })
  }

  const handleAddProduct = async e => {
    e.preventDefault()
    const { width, height, quantity } = productData
    const area = width * height * quantity

    const price = selectedProduct.price + selectedGlass.extra
    const subTotal = area * price
    const newProduct = { ...productData, product: selectedProduct.name, glass: selectedGlass.name, frame: selectedFrame.name, area, price, subTotal, created: Date.now() }
    try {
      updateDocument("quotations", quotationId, { products: arrayUnion(newProduct) })
      setAddModalOpen(false)
      setProductData(initialProductData)
      setSelectedProduct(productOptions[0])
      setSelectedGlass(glassOptions[0])
      setSelectedFrame(frameOptions[0])
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddBulkProducts = async e => {
    e.preventDefault()
    setAddBulkLoading(true)
    for (var i = 0; i < selectedBulkQuantity; i++) {
      const width = 1
      const height = 1
      const quantity = 1
      const area = width * height * quantity

      const price = selectedProduct.price + selectedGlass.extra
      const subTotal = area * price

      const bulkProduct = { product: selectedProduct.name, glass: selectedGlass.name, frame: selectedFrame.name, width, height, quantity, area, price, subTotal, created: Date.now() }

      try {
        await updateDocument("quotations", quotationId, { products: arrayUnion(bulkProduct) })
      } catch (error) {
        console.log(error)
      }
    }
    setAddBulkLoading(false)
    setAddBulkModalOpen(false)
    setProductData(initialProductData)
    setSelectedProduct(productOptions[0])
    setSelectedGlass(glassOptions[0])
    setSelectedFrame(frameOptions[0])
  }

  const makeOrder = async () => {
    try {
      const expandProducts = []

      quotation?.products.forEach(element => {
        for (var i = 0; i < element.quantity; i++) {
          expandProducts.push({ ...element, quantity: 1, subTotal: element.subTotal / element.quantity })
        }
      })
      const docRefId = await addDocument("orders", { customerId: quotation.customerId, quotationId, products: expandProducts })
      await updateDocument("quotations", quotationId, { status: "Closed", orderId: docRefId })
      navigate(`/app/orders/${docRefId}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout title="Quotation Detail">
      <h1 className="my-5 text-xl font-semibold text-gray-700">Quotation {quotationId}</h1>
      <h2 className="my-5 text-xl font-semibold text-gray-700">Status {quotation?.status}</h2>
      <div>
        {quotation && <CustomerCard customer={customers.find(f => f.id === quotation.customerId)} />}
        <div className="lg:flex lg:items-center mt-8">
          <div className="lg:flex-auto">
            <h1 className="my-5 text-xl font-semibold text-gray-700">Products</h1>
          </div>
          <div className="mt-4 lg:mt-0 lg:ml-16 flex items-center space-x-3">
            <Button label="Add Bulk Products" onClick={() => setAddBulkModalOpen(true)} disabled={quotation?.status === "Closed"} />
            <Button label="Add Product" onClick={() => setAddModalOpen(true)} disabled={quotation?.status === "Closed"} />
            {quotation?.products && <Button label="Make Order" onClick={() => makeOrder()} disabled={order?.quotationId === quotationId} />}
            {quotation?.products && <Button label="Open Order" onClick={() => makeOrder()} disabled={!quotation?.orderId} />}
            {quotation && <Button label="Print PDF" onClick={() => setPdfPreviewOpen(true)} />}
          </div>
        </div>
        {loading ? (
          <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
        ) : quotation?.products?.length > 0 ? (
          <Products products={quotation.products} update={updateDocument} quotationId={quotationId} />
        ) : (
          <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Product</div>
        )}
      </div>

      <Modal title="Add Product" isOpen={addModalOpen} closeModal={() => setAddModalOpen(false)}>
        <form onSubmit={handleAddProduct} className="grid gap-y-2">
          <Select options={productOptions} selectedOption={selectedProduct} setSelectedOption={setSelectedProduct} label="Product" required />
          <Select options={glassOptions} selectedOption={selectedGlass} setSelectedOption={setSelectedGlass} label="Glass" required />
          <Select options={frameOptions} selectedOption={selectedFrame} setSelectedOption={setSelectedFrame} label="Frame" required />
          <Input type="number" label="Width (m)" name="width" required value={productData.width} onChange={handleChangeNumber} />
          <Input type="number" label="Height (m)" name="height" required value={productData.height} onChange={handleChangeNumber} />
          <Input type="number" label="Quantity" name="quantity" required value={productData.quantity} onChange={handleChangeNumber} />
          <Button type="submit" label="Add Product" className="mt-4" />
        </form>
      </Modal>

      <Modal title="Add Bulk Products" isOpen={addBulkModalOpen} closeModal={() => setAddBulkModalOpen(false)}>
        <form onSubmit={handleAddBulkProducts} className="grid gap-y-2">
          <Select options={productOptions} selectedOption={selectedProduct} setSelectedOption={setSelectedProduct} label="Product" required />
          <Select options={glassOptions} selectedOption={selectedGlass} setSelectedOption={setSelectedGlass} label="Glass" required />
          <Select options={frameOptions} selectedOption={selectedFrame} setSelectedOption={setSelectedFrame} label="Frame" required />
          <Input type="number" label="Quantity" name="quantity" required value={selectedBulkQuantity} onChange={e => setSelectedBulkQuantity(Number(e.target.value))} />
          <Button type="submit" label="Add Products" className="mt-4" loading={addBulkLoading} />
        </form>
      </Modal>

      <Modal title="" isOpen={pdfPreviewOpen} closeModal={() => setPdfPreviewOpen(false)} className="max-w-[48rem]">
        <div ref={pdfRef}>
          <div className="flex space-x-4">
            <StaticImage src="../../../images/vab-icon.png" alt="vav icon" className="w-16 h-16 mt-0.5" />
            <div>
              <p className="text-xs font-semibold text-red-500">V.A.B MANUFACTURE LTD.</p>
              <p className="text-[0.65rem] font-semibold text-gray-900">Providence, P.O Box 798</p>
              <p className="text-[0.65rem] text-gray-900">Tel: +2484374097</p>
              <p className="text-[0.65rem] text-gray-900">Fax: +2484374438</p>
              <p className="text-[0.65rem] text-gray-900">Email: vabmanufacture@gmail.com</p>
            </div>
          </div>
          {quotation && (
            <CustomerCard customer={customers.find(f => f.id === quotation.customerId)} titleClassName="my-1 mt-2 text-sm" className="p-1 grid-cols-3 sm:grid-cols-3 gap-1.5 sm:gap-x-1.5 sm:gap-y-1.5" rowTitleClassName="text-[0.65rem]" rowDescriptionClassName="text-[0.65rem] sm:mt-[0px]" />
          )}

          {quotation?.products?.length > 0 ? (
            <Products products={quotation.products} update={updateDocument} quotationId={quotationId} pdfPreview={true} cellClassName="text-xs" headerCellClassName="text-[0.65rem]" summaryClassName="mt-0" summaryTitleClassName="text-[0.65rem]" summaryValueClassName="text-[0.65rem]" />
          ) : (
            <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Product</div>
          )}
          <div className="mt-2">
            <p className="text-xs font-medium text-gray-900">Please note:</p>
            {nodeList.map(node => (
              <p key={node} className="text-[0.65rem] text-gray-900 pt-0.5">
                {node}
              </p>
            ))}
            <p className="text-[0.65rem] text-gray-900 font-medium pt-0.5 mt-2 ml-10">
              Cheque / Bank Transfers to: <span className="font-semibold">VAB (PTY) LTD</span>, at <span className="font-semibold">Absa Bank</span>, Account Number: <span className="font-semibold">0101165523</span>
            </p>
          </div>
        </div>
        <ReactToPdf targetRef={pdfRef} filename={`${customers.find(f => f.id === quotation.customerId)?.name}-${quotationId}.pdf`} x={4} y={2}>
          {({ toPdf }) => (
            <div className="w-full flex justify-end mt-6">
              <Button
                onClick={() => {
                  toPdf()
                  setPdfPreviewOpen(false)
                }}
                label="Download PDF"
              />
            </div>
          )}
        </ReactToPdf>
      </Modal>
    </Layout>
  )
}
