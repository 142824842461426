import React from "react"
import { Router } from "@reach/router"

import Seo from "../components/seo"
import PrivateRoute from "../components/privateRoute"
// import Login from "./login"
import Dashboard from "../components/app/dashboard"
import Customers from "../components/app/customers"
import Quotations from "../components/app/main/quotations"
import QuotationDetails from "../components/app/quotationDetails"
import Orders from "../components/app/main/orders"
import OrderDetails from "../components/app/orderDetails"
import Payments from "../components/app/payments"
import Users from "../components/app/users"

export default function AppPage() {
  return (
    <>
      <Seo title="App" />
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/quotations" component={Quotations} />
        <PrivateRoute path="/quotations/:quotationId" component={QuotationDetails} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/orders/:orderId" component={OrderDetails} />
        <PrivateRoute path="/payments" component={Payments} />
        <PrivateRoute path="/users" component={Users} />
      </Router>
    </>
  )
}
