import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getNavigation } from "../../utils"

const LinkItem = ({ item }) => (
  <Link to={item.to} className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white" activeClassName="bg-gray-900 text-white">
    <item.icon className="mr-3 flex-shrink-0 h-6 w-6 group-hover:text-gray-300" aria-hidden="true" />
    {item.name}
  </Link>
)

export default function Sidebar({ role }) {
  const navigation = getNavigation(role)
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
          <Link to="/">
            <span className="sr-only">VAB</span>
            <StaticImage src="../../images/vab-icon.png" alt="VAB Icon" width={50} height={50} />
          </Link>
          <Link to="/app/dashboard" className="text-lg ml-2 mt-1 font-medium text-gray-900 hover:text-gray-700">
            VAB Manufactuers
          </Link>
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto">
          <nav className="flex-1 px-2 py-4 space-y-1">
            {navigation.map(item => (
              <LinkItem key={item.name} item={item} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
