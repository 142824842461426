// import { CheckIcon, PencilIcon, PlusIcon } from "@heroicons/react/outline"
import React, { useEffect, useMemo, useState } from "react"
import { useTable, useGlobalFilter } from "react-table"
import { roleOptions, USERS_COLUMNS } from "../../../data"
import { getUser, setUser } from "../../../utils"
import Input from "../../form/input"
import Select from "../../form/select"

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
  editableRowIndex, // index of the row we requested for editing
}) => {
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateData(index, id, value)
  }

  const handleSelectChange = selectedProduct => {
    updateData(index, id, selectedProduct.name)
  }

  // If the initialValue is changed externall, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return index === editableRowIndex && !["name", "email"].includes(id) ? (
    id === "role" ? (
      <Select options={roleOptions} selectedOption={roleOptions.find(option => option.name === value)} setSelectedOption={option => handleSelectChange(option)} inputClassName="py-1" />
    ) : (
      <input value={value} onChange={onChange} onBlur={onBlur} className="focus:ring-0 focus:border-blue-500 focus:outline-none block w-full sm:text-sm border border-gray-300 rounded py-1" />
    )
  ) : (
    <p>{value}</p>
  )
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

export default function UserTable({ users, update }) {
  const columns = useMemo(() => USERS_COLUMNS, [])

  const [data, setData] = useState([])
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [editableRowIndex, setEditableRowIndex] = useState(null)

  useEffect(() => {
    setData(users)
  }, [users])

  const updateData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateData,
      editableRowIndex,
      setEditableRowIndex,
    },
    useGlobalFilter,
    hooks => {
      hooks.allColumns.push(columns => [
        // other hooks such as selection hook
        ...columns,
        // edit hook
        {
          accessor: "action",
          id: "action",
          Header: "Action",
          Cell: ({ row, setEditableRowIndex, editableRowIndex }) => (
            <button
              className={`font-medium text-sm flex items-center space-x-1 ${editableRowIndex !== row.index ? "text-blue-500 hover:text-blue-600" : "text-green-500 hover:text-green-600"}`}
              onClick={() => {
                const currentIndex = row.index
                if (editableRowIndex !== currentIndex) {
                  setEditableRowIndex(currentIndex)
                } else {
                  setEditableRowIndex(null)
                  update("users", row.original.id, row.original)
                  const user = getUser()
                  setUser({ ...user, role: row.original.role })
                }
              }}
            >
              {editableRowIndex !== row.index ? <>Edit</> : <>Save</>}
            </button>
          ),
        },
      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance

  return (
    <div className="mt-4 shadow rounded-md bg-white">
      <div className="p-2">
        <Input type="text" placeholder="Filter your users ..." value={globalFilter || ""} onChange={e => setGlobalFilter(e.target.value)} className="py-1.5" />
      </div>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className={rowIndex % 2 === 0 ? undefined : "bg-gray-50"}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
