import React from "react"
import { useState } from "react"
import Header from "./header"
import Sidebar from "./sidebar"
import MobileDrawer from "./mobileDrawer"
import { getUser } from "../../utils"

export default function Layout({ title, children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const user = getUser()
  return (
    <>
      <MobileDrawer role={user.role} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar role={user.role} />
      <div className="md:pl-64 flex flex-col bg-gray-50 min-h-screen">
        <Header title={title} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 overflow-auto">
          <div className="py-6">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
          </div>
        </main>
      </div>
    </>
  )
}
