import React, { useContext, useEffect, useState } from "react"

import Layout from "../../../layouts/app"
import List from "../../lists/read"
import { ORDERS_COLUMNS } from "../../../data"

import StoreContext from "../../../context/storeContext"
import { getOrdersByRole } from "../../../utils"

export default function Orders() {
  const { loading, orders, customers } = useContext(StoreContext)
  const [ordersByRole, setOrdersByRole] = useState([])
  useEffect(() => {
    setOrdersByRole(getOrdersByRole(orders))
  }, [orders])
  return (
    <Layout title="Orders">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Orders</h1>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : orders.length > 0 ? (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto" />
          </div>
          <List main={ordersByRole} customers={customers} cols={ORDERS_COLUMNS} />
        </>
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Order</div>
      )}
    </Layout>
  )
}
