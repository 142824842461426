import React, { useState, useContext, useEffect } from "react"

import Modal from "../../common/modal"
import Button from "../../form/button"
import Input from "../../form/input"
import Layout from "../../../layouts/app"
import Products from "./products"
import { frameOptions, glassOptions, paymentTypes, productOptions, ORDERS_SALES_STAGE, ORDERS_BUILD_STAGE } from "../../../data"
import Select from "../../form/select"
import CustomerCard from "./customer"
import StoreContext from "../../../context/storeContext"
import { arrayUnion } from "firebase/firestore"
import PaymentTable from "../payments/table"
import { toast } from "react-toastify"

const initialProductData = {
  width: "",
  height: "",
  quantity: "",
}

export default function OrderDetails({ orderId }) {
  const { loading, orders, customers, payments: allPayments, updateDocument, addDocument } = useContext(StoreContext)
  const order = orders.find(f => f.id === orderId)

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false)
  const [productData, setProductData] = useState(initialProductData)
  const [selectedProduct, setSelectedProduct] = useState(productOptions[0])
  const [selectedGlass, setSelectedGlass] = useState(glassOptions[0])
  const [selectedFrame, setSelectedFrame] = useState(frameOptions[0])

  const [selectedSalesStage, setSelectedSalesStage] = useState("")
  const [selectedBuildStage, setSelectedBuildStage] = useState("")

  const [amount, setAmount] = useState("")
  const [selectedType, setSelectedType] = useState(paymentTypes[0])
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [stageUpdateLoading, setStageUpdateLoading] = useState(false)

  useEffect(() => {
    setPayments(allPayments.filter(payment => payment.orderId === orderId))
  }, [allPayments, orderId])

  useEffect(() => {
    if (order) {
      setSelectedSalesStage({ name: order?.salesStage })
      setSelectedBuildStage({ name: order?.buildStage })
    }
  }, [order])

  const handleChangeNumber = e => {
    const { name, value } = e.target
    setProductData({ ...productData, [name]: Number(value) })
  }

  const handleAddProduct = async e => {
    e.preventDefault()
    const { width, height, quantity } = productData
    const area = width * height * quantity

    const price = selectedProduct.price + selectedGlass.extra
    const subTotal = area * price
    const newProduct = { ...productData, product: selectedProduct.name, glass: selectedGlass.name, frame: selectedFrame.name, area, price, subTotal, created: Date.now() }
    try {
      updateDocument("orders", orderId, { products: arrayUnion(newProduct) })
      setAddModalOpen(false)
      setProductData(initialProductData)
      setSelectedProduct(productOptions[0])
      setSelectedGlass(glassOptions[0])
      setSelectedFrame(frameOptions[0])
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddPayment = async e => {
    e.preventDefault()
    setPaymentLoading(true)
    try {
      await addDocument("payments", { orderId, customerId: order.customerId, amount: Number(amount), type: selectedType.name, status: "New" })
      setAddPaymentModalOpen(false)
      setAmount("")
      setSelectedType(paymentTypes[0])
      setPaymentLoading(false)
    } catch (error) {
      console.log(error)
      setPaymentLoading(false)
    }
  }

  const handleValidate = paymentId => {
    updateDocument("payments", paymentId, { status: "Processed" })
  }

  const handleStage = async e => {
    e.preventDefault()
    setStageUpdateLoading(true)
    const salesStage = selectedSalesStage?.name
    const buildStage = selectedBuildStage?.name
    await updateDocument("orders", orderId, { salesStage, buildStage })
    setStageUpdateLoading(false)
    toast.success("Stage updated sccessfully!")
  }

  return (
    <Layout title="Order Detail">
      <h1 className="my-5 text-xl font-semibold text-gray-700">Order {orderId}</h1>
      <form onSubmit={handleStage} className="sm:flex sm:items-end sm:space-x-3 space-y-3">
        <div className="flex space-x-3">
          <Select options={ORDERS_SALES_STAGE} selectedOption={selectedSalesStage} setSelectedOption={setSelectedSalesStage} required label="Sales Stage" />
          <Select options={ORDERS_BUILD_STAGE} selectedOption={selectedBuildStage} setSelectedOption={setSelectedBuildStage} required label="Build Stage" />
        </div>
        <Button type="submit" label="Update" loading={stageUpdateLoading} />
      </form>

      {order && <CustomerCard customer={customers.find(f => f.id === order.customerId)} />}
      <div className="md:flex md:items-center mt-8">
        <div className="md:flex-auto">
          <h1 className="my-5 text-xl font-semibold text-gray-700">Products</h1>
        </div>
        <div className="mt-4 md:mt-0 md:ml-16 flex items-center space-x-2">
          <Button label="Add Product" onClick={() => setAddModalOpen(true)} />
          <Button label="Add Payment" onClick={() => setAddPaymentModalOpen(true)} />
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : order?.products?.length > 0 ? (
        <Products products={order.products} update={updateDocument} orderId={orderId} payments={payments} />
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Product</div>
      )}

      <div className="sm:flex sm:items-center mt-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Payments</h1>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : payments.length > 0 ? (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto" />
          </div>
          <PaymentTable main={payments} customers={customers} handleValidate={handleValidate} update={updateDocument} />
        </>
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Payments</div>
      )}

      <Modal title="Add Product" isOpen={addModalOpen} closeModal={() => setAddModalOpen(false)}>
        <form onSubmit={handleAddProduct} className="grid gap-y-2">
          <Select options={productOptions} selectedOption={selectedProduct} setSelectedOption={setSelectedProduct} label="Product" required />
          <Select options={glassOptions} selectedOption={selectedGlass} setSelectedOption={setSelectedGlass} label="Glass" required />
          <Select options={frameOptions} selectedOption={selectedFrame} setSelectedOption={setSelectedFrame} label="Frame" required />
          <Input type="number" label="Width (m)" name="width" required value={productData.width} onChange={handleChangeNumber} />
          <Input type="number" label="Height (m)" name="height" required value={productData.height} onChange={handleChangeNumber} />
          <Input type="number" label="Quantity" name="quantity" required value={productData.quantity} onChange={handleChangeNumber} />
          <Button type="submit" label="Add Product" className="mt-4" />
        </form>
      </Modal>
      <Modal title="Add Payment" isOpen={addPaymentModalOpen} closeModal={() => setAddPaymentModalOpen(false)}>
        <form onSubmit={handleAddPayment} className="grid gap-y-2">
          <Input type="number" label="Amount" name="amount" required value={amount} onChange={e => setAmount(e.target.value)} prefix="SCR" />
          <Select options={paymentTypes} selectedOption={selectedType} setSelectedOption={setSelectedType} label="Frame" required />
          <Button type="submit" label={paymentLoading ? "Loading..." : "Add Payment"} disabled={paymentLoading} className="mt-4" />
        </form>
      </Modal>
    </Layout>
  )
}
