import React from "react"

const CardRow = ({ title, description }) => (
  <div className="flex space-x-4 justify-between sm:flex-col sm:space-x-0">
    <dt className="text-sm font-medium text-gray-900">{title}</dt>
    <dd className="sm:mt-0.5 text-sm text-gray-900">{description}</dd>
  </div>
)

const CustomerCard = ({ customer }) => {
  return (
    <div>
      <h1 className="my-5 text-xl font-semibold text-gray-700">Customer</h1>
      <div className="bg-white p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-x-6 sm:gap-y-3 rounded-md shadow">
        <CardRow title="Name" description={customer?.name} />
        <CardRow title="Email" description={customer?.email} />
        <CardRow title="Phone" description={customer?.phone} />
        <CardRow title="Alt Phone" description={customer?.altPhone} />
        <CardRow title="District" description={customer?.district} />
        <CardRow title="Address" description={customer?.address} />
      </div>
    </div>
  )
}

export default CustomerCard
