import React, { useEffect, useMemo, useState } from "react"
import { useTable } from "react-table"
import { PAYMENTS_COLUMNS } from "../../../data"
import { getUser } from "../../../utils"

export default function PaymentTable({ main, customers, handleValidate }) {
  const columns = useMemo(() => PAYMENTS_COLUMNS, [])

  const [data, setData] = useState([])

  useEffect(() => {
    const results = main.map((m, i) => {
      const { name, phone, altPhone } = customers.find(f => f.id === m.customerId) || ""
      return { ...m, name, phone, altPhone }
    })
    setData(results)
  }, [main, customers])

  const tableInstance = useTable(
    {
      columns,
      data,
      handleValidate,
    },
    hooks => {
      hooks.allColumns.push(columns => [
        // other hooks such as selection hook
        ...columns,
        // edit hook
        {
          accessor: "action",
          id: "action",
          Header: "Action",
          Cell: ({ row }) =>
            row.original.status === "New" &&
            getUser().role === "manager" && (
              <button className="font-medium text-sm flex items-center space-x-1 text-green-500 hover:text-green-600" onClick={() => handleValidate(row.original.id)}>
                Validate
              </button>
            ),
        },
      ])
    }
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  return (
    <div className="mt-4 shadow rounded-md bg-white">
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="w-full divide-y divide-gray-300 border-b border-gray-100">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className={rowIndex % 2 === 0 ? undefined : "bg-gray-50"}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
