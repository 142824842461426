import React, { useContext } from "react"

import Layout from "../../../layouts/app"
import List from "../../lists/read"
import { QUOTATIONS_COLUMNS } from "../../../data"

import StoreContext from "../../../context/storeContext"

export default function Quotations() {
  const { loading, quotations, customers } = useContext(StoreContext)

  return (
    <Layout title="Quotations">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Quotations</h1>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-medium">Loading...</div>
      ) : quotations.length > 0 ? (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto" />
          </div>
          <List main={quotations} customers={customers} cols={QUOTATIONS_COLUMNS} />
        </>
      ) : (
        <div className="w-full h-64 flex items-center justify-center text-xl text-gray-400 font-semibold">No Quotations</div>
      )}
    </Layout>
  )
}
