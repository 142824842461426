import React from "react"
import { navigate } from "gatsby"
import { getUser, isLoggedIn } from "../utils/auth"

export default function PrivateRoute({ component: Component, path, ...rest }) {
  const user = getUser()
  if (!isLoggedIn()) {
    navigate(`/login`)
    return null
  } else if (!user.role || user.role === "user") {
    navigate(`/exit`)
    return null
  } else if (["/payments", "/users"].includes(path) && user.role !== "manager") {
    navigate(`/app/dashboard`)
    return null
  }
  return <Component path={path} {...rest} />
}
