import React from "react"

const CardRow = ({ title, description, rowClassName, rowTitleClassName, rowDescriptionClassName }) => (
  <div className={`flex space-x-4 justify-between sm:flex-col sm:space-x-0 ${rowClassName ?? ""}`}>
    <dt className={`text-sm font-medium text-gray-900 ${rowTitleClassName ?? ""}`}>{title}</dt>
    <dd className={`sm:mt-0.5 text-sm text-gray-900 ${rowDescriptionClassName ?? ""}`}>{description}</dd>
  </div>
)

const CustomerCard = ({ customer, className, titleClassName, rowClassName, rowTitleClassName, rowDescriptionClassName }) => (
  <div>
    <h1 className={`my-4 text-xl font-semibold text-gray-700 ${titleClassName ?? ""}`}>Customer</h1>
    <div className={`bg-white p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-x-6 sm:gap-y-3 rounded-md shadow ${className ?? ""}`}>
      <CardRow title="Name" description={customer?.name} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
      <CardRow title="Email" description={customer?.email} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
      <CardRow title="Phone" description={customer?.phone} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
      <CardRow title="Alt Phone" description={customer?.altPhone} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
      <CardRow title="District" description={customer?.district} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
      <CardRow title="Address" description={customer?.address} rowClassName={rowClassName} rowTitleClassName={rowTitleClassName} rowDescriptionClassName={rowDescriptionClassName} />
    </div>
  </div>
)

export default CustomerCard
